// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-advertising-js": () => import("./../../../src/pages/services/advertising.js" /* webpackChunkName: "component---src-pages-services-advertising-js" */),
  "component---src-pages-services-cmo-js": () => import("./../../../src/pages/services/cmo.js" /* webpackChunkName: "component---src-pages-services-cmo-js" */),
  "component---src-pages-services-content-js": () => import("./../../../src/pages/services/content.js" /* webpackChunkName: "component---src-pages-services-content-js" */),
  "component---src-pages-services-products-js": () => import("./../../../src/pages/services/products.js" /* webpackChunkName: "component---src-pages-services-products-js" */),
  "component---src-pages-services-studio-js": () => import("./../../../src/pages/services/studio.js" /* webpackChunkName: "component---src-pages-services-studio-js" */),
  "component---src-pages-services-support-js": () => import("./../../../src/pages/services/support.js" /* webpackChunkName: "component---src-pages-services-support-js" */),
  "component---src-pages-services-websites-js": () => import("./../../../src/pages/services/websites.js" /* webpackChunkName: "component---src-pages-services-websites-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

